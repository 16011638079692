import Vue from "vue";
import VueRouter from "vue-router";

import authRoutes from "@/modules/auth/router";
import entityRoutes from "@/modules/entity/router";
import employeeRoutes from "@/modules/employee/router";
import userRoutes from "@/modules/entity/modules/user/router";

import { GC_AUTH_TOKEN } from "@/constants";
import { apolloClient } from "@/plugins/vue-apollo";
import GET_ME from "@/modules/auth/graphql/query/GetMe.gql";

Vue.use(VueRouter);

const routes = [
  ...authRoutes,
  ...entityRoutes,
  ...employeeRoutes,
  ...userRoutes,
  { path: "", redirect: "/login" },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(async (to, from, next) => {
  if (to.matched.some((route) => route.meta.requiresAuth)) {
    const token = window.localStorage.getItem(GC_AUTH_TOKEN);
    const loginRoute = {
      path: "/login",
      query: { redirect: to.fullPath },
    };
    if (token) {
      try {
        await apolloClient.query({
          query: GET_ME,
          fetchPolicy: "network-only",
        });
        return next();
      } catch (error) {
        const noInternet = window.navigator.onLine ? false : true;
        if (noInternet) {
          alert("No Internet Connection");
          return next(false);
        } else {
          console.log("Auto Login Error: ", error);
          return next(loginRoute);
        }
      }
    }
    return next(loginRoute);
  }
  next();
});

export default router;
