<template>
  <div class="text-center ma-2">
    <v-snackbar v-model="snackbars" :timeout="timeout">
      {{ text }}
      <template v-slot:action="{ attrs }">
        <v-btn color="pink" text v-bind="attrs" @click="$emit('close')">
       {{$t('close')}}
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>
<script>
export default {
  name: "WhirelabSnackbar",
  props: {
    snackbar: Boolean,
    timeout: Number,
    text:String
  },
  computed: {
    snackbars() {
      return this.snackbar;
    },
  },
};
</script>