<template>
  <v-card class="mx-auto" @click="$emit('clicked', role.id)">
    <v-card-text>
      <div class="d-flex justify-space-between align-center">
        <div class="text-left font-weight-medium text-caption">
          {{ role.name }}
        </div>

        <div>
          <v-btn icon @click="show = !show">
            <v-icon>{{ show ? "mdi-chevron-up" : "mdi-chevron-down" }}</v-icon>
          </v-btn>
        </div>
      </div>

      <v-expand-transition>
        <div v-show="show">
          <div class="text-left black--text text-caption text-justify">
            {{ role.description }}
          </div>
        </div>
      </v-expand-transition>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "ExpansionCardRole",

  props: ["role"],

  data: () => ({
    show: false,
  }),
};
</script>