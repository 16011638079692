<template>
  <v-navigation-drawer class="secondary" v-model="drawer" app dark>
    <!-- <img alt="Vue logo" src="../assets/logo-black.png" class="mt-5" /> -->
    <v-list-item class="px-2 py-1 secondary" dark>
      <v-list-item-avatar>
        <v-img src="/logo.png" contain></v-img>
      </v-list-item-avatar>

      <v-list-item-title class="title">WhireLab</v-list-item-title>
    </v-list-item>
    <v-list dense nav>
      <template v-for="item in items">
        <v-list-item
          v-if="!item.childrens"
          :key="item.title"
          :to="item.url"
          :exact="item.exact"
          link
          active-class="primary"
          class="white--text"
        >
          <v-list-item-icon>
            <v-icon color="white">{{ item.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title
              class="text-left text-subtitle-1 font-weight-bold"
              >{{ item.title }}</v-list-item-title
            >
          </v-list-item-content>
        </v-list-item>
      </template>
      <br /><br /><br /><br /><br /><br /><br />
      <!-- <div class="my-5 mx-2">
       
        <v-divider class="primary"></v-divider>
      </div> -->

      <v-list-group
        v-if="currentUser && accessLevel(currentUser, 'internal_section')"
        :value="false"
        prepend-icon="mdi-home-variant"
      >
        <template v-slot:activator>
          <v-list-item-title class="text-subtitle-1 font-weight-bold">{{
            $t("Inner_section")
          }}</v-list-item-title>
        </template>

        <template v-for="item in items">
          <v-list-group
            v-if="item.childrens && item.show"
            :prepend-icon="``"
            :key="item.title"
            no-action
            active-class="primary white--text "
            sub-group
          >
            <template v-slot:activator>
              <v-list-item>
                <v-list-item-icon>
                  <v-icon>{{ item.icon }}</v-icon>
                </v-list-item-icon>
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </v-list-item>
            </template>

            <template v-for="child in item.childrens">
              <v-list-group
                v-if="child.childrens && child.show"
                :key="child.title"
                no-action
                sub-group
                active-class="primary"
              >
                <template v-slot:activator>
                  <v-list-item-content>
                    <v-list-item-title class="text-subtitle-2"
                      >{{ child.title }}
                    </v-list-item-title>
                  </v-list-item-content>
                </template>

                <v-list-item
                  v-for="(c, i) in child.childrens"
                  :key="i"
                  :to="c.url"
                  :exact="true"
                  link
                  active-class="primary"
                  class="white--text text-subtitle-2"
                >
                  <v-list-item-title v-text="c.title"> </v-list-item-title>

                  <!-- <v-list-item-icon>
                  <v-icon v-text="icon"></v-icon>
                </v-list-item-icon> -->
                </v-list-item>
              </v-list-group>

              <v-list-item
                v-else-if="child.show"
                :key="child.url"
                :to="child.url"
                :exact="item.exact"
                router
                active-class="primary--text text-subtitle-2"
                class="px-3"
                style="width: 95%; margin-left: 5%"
              >
                <v-list-item>
                  <v-list-item-icon>
                    <v-icon class="white--text" v-text="child.icon"></v-icon>
                  </v-list-item-icon>
                  <v-list-item-title
                    class="text-left text-subtitle-2"
                    v-text="child.title"
                  ></v-list-item-title>
                </v-list-item>
              </v-list-item>
            </template>
          </v-list-group>
        </template>
      </v-list-group>

      <v-divider class="primary"></v-divider>

      <v-list-group
        v-if="currentUser && accessLevel(currentUser, 'external_section')"
        :value="false"
        prepend-icon="mdi-home-variant-outline"
      >
        <template v-slot:activator>
          <v-list-item-title class="text-subtitle-1 font-weight-bold">{{
            $t("outer_section")
          }}</v-list-item-title>
        </template>

        <template v-for="item in itemsExt">
          <v-list-group
            v-if="item.childrens && item.show"
            :prepend-icon="``"
            :key="item.title"
            no-action
            active-class="primary white--text "
            sub-group
          >
            <template v-slot:activator>
              <v-list-item>
                <v-list-item-icon>
                  <v-icon>{{ item.icon }}</v-icon>
                </v-list-item-icon>
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </v-list-item>
            </template>

            <template v-for="child in item.childrens">
              <v-list-group
                v-if="child.childrens && child.show"
                :key="child.title"
                no-action
                sub-group
                active-class="primary"
              >
                <template v-slot:activator>
                  <v-list-item-content>
                    <v-list-item-title class="text-subtitle-2"
                      >{{ child.title }}
                    </v-list-item-title>
                  </v-list-item-content>
                </template>

                <v-list-item
                  v-for="(c, i) in child.childrens"
                  :key="i"
                  :to="c.url"
                  :exact="true"
                  link
                  active-class="primary"
                  class="white--text text-subtitle-2"
                >
                  <v-list-item-title v-text="c.title"> </v-list-item-title>

                  <!-- <v-list-item-icon>
                  <v-icon v-text="icon"></v-icon>
                </v-list-item-icon> -->
                </v-list-item>
              </v-list-group>

              <v-list-item
                v-else-if="child.show"
                :key="child.url"
                :to="child.url"
                :exact="item.exact"
                router
                active-class="primary--text text-subtitle-2"
                class="px-3"
                style="width: 95%; margin-left: 5%"
              >
                <v-list-item>
                  <v-list-item-icon>
                    <v-icon class="white--text" v-text="child.icon"></v-icon>
                  </v-list-item-icon>
                  <v-list-item-title
                    class="text-left text-subtitle-2"
                    v-text="child.title"
                  ></v-list-item-title>
                </v-list-item>
              </v-list-item>
            </template>
          </v-list-group>
        </template>
      </v-list-group>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import { mapGetters } from "vuex";
import accessLevelMixins from "@/mixins/access-level";

export default {
  props: ["propDrawer"],
  mixins: [accessLevelMixins],

  data: () => ({
    drawer: true,
    selectedItem: 0,
  }),

  watch: {
    propDrawer(val) {
      this.drawer = val;
    },
  },

  computed: {
    ...mapGetters({
      currentUser: "auth/getCurrentUser",
    }),
    items() {
      if (this.currentUser) {
        return [
          {
            title: this.$t("home"),
            icon: " mdi-home",
            url: "/entity/dashboard",
            show: this.accessLevel(this.currentUser, "internal_section"),
            exact: true,
          },
          {
            title: this.$t("employees"),
            icon: "mdi-account-group",
            route: "/entity/employees",
            show: this.accessLevel(this.currentUser, "employees"),
            childrens: [
              {
                title: this.$t("list"),
                url: "/entity/employees/list",
                icon: "mdi-format-list-bulleted-square",
                show: this.accessLevel(this.currentUser, "list_employees"),
              },
              {
                title: this.$t("add_new"),
                url: "/entity/employees/add",
                icon: "mdi-plus",
                show: this.accessLevel(this.currentUser, "add_employee"),
              },
            ],
          },
          {
            title: this.$t("departments"),
            icon: "mdi-folder",
            route: "/entity/departments",
            show: this.accessLevel(this.currentUser, "departments"),
            childrens: [
              {
                title: this.$t("list"),
                url: "/entity/departments/list",
                icon: "mdi-format-list-bulleted-square",
                show: this.accessLevel(this.currentUser, "list_departments"),
              },
              {
                title: this.$t("add_new"),
                url: "/entity/departments/add",
                icon: "mdi-plus",
                show: this.accessLevel(this.currentUser, "add_department"),
              },
            ],
          },
          // {
          //   title: "Meetings",
          //   icon: "mdi-calendar-multiselect",
          //   route: "/entity/meetings/list",
          //   show: true,
          //   childrens: [
          //     {
          //       title: this.$t('list'),
          //       url: "/entity/meetings",
          //       icon: "mdi-format-list-bulleted-square",
          //     },
          //     {
          //       title: this.$t('add_new'),
          //       url: "/entity/meetings/add",
          //       icon: "mdi-plus",
          //     },
          //   ],
          // },
          {
            title: this.$t("trainings"),
            icon: "mdi-book-open-variant",
            route: "/entity/trainings",
            show: this.accessLevel(this.currentUser, "trainings"),
            childrens: [
              {
                title: this.$t("list"),
                url: "/entity/trainings/list",
                icon: "mdi-format-list-bulleted-square",
                show: this.accessLevel(this.currentUser, "list_trainings"),
              },
              {
                title: this.$t("add_new"),
                url: "/entity/trainings/add",
                icon: "mdi-plus",
                show: this.accessLevel(this.currentUser, "add_trainings"),
              },
            ],
          },
        ];
      } else {
        return [];
      }
    },
    itemsExt() {
      if (this.currentUser) {
        return [
          {
            title: this.$t("home"),
            icon: " mdi-home",
            url: "/entity/dashboard",
            show: this.accessLevel(this.currentUser, "external_section"),
            exact: true,
          },
          {
            title: this.$t("trainees"),
            icon: "mdi-account-group",
            route: "/entity/trainees",
            show: this.accessLevel(this.currentUser, "trainees"),
            childrens: [
              {
                title: this.$t("list"),
                url: "/entity/trainees/list",
                icon: "mdi-format-list-bulleted-square",
                show: this.accessLevel(this.currentUser, "list_trainees"),
              },
              {
                title: this.$t("add_new"),
                url: "/entity/trainees/add",
                icon: "mdi-plus",
                show: this.accessLevel(this.currentUser, "add_trainee"),
              },
            ],
          },
          {
            title: this.$t("clients"),
            icon: "mdi-account-supervisor",
            route: "/entity/clients",
            show: this.accessLevel(this.currentUser, "clients"),
            childrens: [
              {
                title: this.$t("list"),
                url: "/entity/clients/list",
                icon: "mdi-format-list-bulleted-square",
                show: this.accessLevel(this.currentUser, "list_clients"),
              },
              {
                title: this.$t("add_new"),
                url: "/entity/clients/add",
                icon: "mdi-plus",
                show: this.accessLevel(this.currentUser, "add_client"),
              },
            ],
          },
          // {
          //   title: "Meetings",
          //   icon: "mdi-calendar-multiselect",
          //   route: "/entity/meetings/list",
          //   show: true,
          //   childrens: [
          //     {
          //       title: this.$t('list'),
          //       url: "/entity/meetings",
          //       icon: "mdi-format-list-bulleted-square",
          //     },
          //     {
          //       title: this.$t('add_new'),
          //       url: "/entity/meetings/add",
          //       icon: "mdi-plus",
          //     },
          //   ],
          // },
          {
            title: this.$t("trainings"),
            icon: "mdi-book-open-variant",
            route: "/entity/ext-trainings",
            show: this.accessLevel(this.currentUser, "external_trainings"),
            childrens: [
              {
                title: this.$t("list"),
                url: "/entity/ext-trainings/list",
                icon: "mdi-format-list-bulleted-square",
                show: this.accessLevel(
                  this.currentUser,
                  "list_external_trainings"
                ),
              },
              {
                title: this.$t("add_new"),
                url: "/entity/ext-trainings/add",
                icon: "mdi-plus",
                show: this.accessLevel(
                  this.currentUser,
                  "add_external_training"
                ),
              },
            ],
          },
        ];
      } else {
        return [];
      }
    },
  },
};
</script>

<style scoped>
.workspace-btn {
  min-width: 5px !important;
  width: 95%;
  height: 95%;
}

.v-list__group__header__prepend-icon .v-icon {
  color: red !important;
}
</style>