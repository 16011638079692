const ListMeetings = () => import("../views/ListMeetings.vue");
const AddOrEditMeeting = () => import("../views/AddOrEditMeeting.vue");

export default [
  {
    path: "meetings/list",
    component: ListMeetings,
    meta: { requiresAuth: true },
    name: "meetings.list",
  },
  {
    path: "meetings/add",
    component: AddOrEditMeeting,
    meta: { requiresAuth: true },
    name: "meetings.add",
    props: true,
  },
];
