<template>
  <div>
    <navigation-drawer :propDrawer="drawer" />
    <app-bar @changeDrawer="changedDrawer" />
    <v-main>
      <slot />
    </v-main>
  </div>
</template>

<script>
import NavigationDrawer from "@/components/NavigationDrawer";
import AppBar from "@/components/AppBar";
export default {
  components: { AppBar, NavigationDrawer },

  data: () => ({
    drawer: true,
  }),

  methods: {
    changedDrawer(val) {
      this.drawer = val;
    },
  },
};
</script>