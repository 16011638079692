<template>
  <div>
    <h2>Order List</h2>
    <table>
      <thead>
        <tr>
          <th>ID</th>
          <th>Status</th>
          <th>Total</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="order in orders" :key="order.id">
          <td>{{ order.id }}</td>
          <td>{{ order.status }}</td>
          <td>{{ order.total }}</td>
          <td>
            <button @click="startEdit(order)">
              Edit
            </button>
            <button v-if="order.editing" @click="saveEdit(order)">Save</button>
            <button v-if="order.editing" @click="cancelEdit(order)">
              Cancel
            </button>
          </td>
        </tr>
        <tr v-if="newOrder">
          <td><input v-model="newOrder.id" /></td>
          <td><input v-model="newOrder.status" /></td>
          <td><input v-model="newOrder.total" /></td>
          <td>
            <button @click="addOrder">Add</button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import GET_ORDERS from "@/graphql/query/GetOrders.gql";
import UPDATE_ORDER from "@/graphql/mutation/UpdateOrder.gql";
import ORDER_UPDATED from "@/graphql/subscription/OrderUpdated.gql";
export default {
  data() {
    return {
      orders: [],
      // orders: [
      //   { id: 1, status: "Pending", total: 100.0 },
      //   { id: 2, status: "Shipped", total: 50.0 },
      //   { id: 3, status: "Delivered", total: 75.0 },
      // ],
      newOrder: null,
    };
  },
  apollo: {
    orders: {
      query: GET_ORDERS,
    },

    $subscribe: {
    // When a tag is added
    orderUpdated: {
      query: ORDER_UPDATED,
      result ({ data }) {
        console.log(data.orderUpdated)
        const findIndex = this.orders.findIndex((order) => order.id === data.orderUpdated.id);
        this.orders.splice(findIndex, 1, data.orderUpdated);
      },
    },
  },
  },
  methods: {
    async startEdit(order) {
      await this.$apollo.mutate({
          mutation: UPDATE_ORDER,
          variables: {
            id: "1",
            status: order.status === "PAID" ? "CREATED" : "PAID",
          },
          update: (store, { data: { updateOrder } }) => {
            const data = store.readQuery({ query: GET_ORDERS });
            const findedOrderIndex = data.orders.findIndex(
              (findOrder) => findOrder.id === order.id
            );
            data.orders.splice(findedOrderIndex, 1, updateOrder);
            store.writeQuery({ query: GET_ORDERS, data });
          },
        });
    },
    saveEdit(order) {
      order.editing = true;
    },
    cancelEdit(order) {
      order.editing = false;
    },
    addOrder() {
      this.orders.push(this.newOrder);
      this.newOrder = null;
    },
  },
};
</script>
