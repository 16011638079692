<template>
  <v-navigation-drawer class="secondary" v-model="drawer" app dark>
    <!-- <img alt="Vue logo" src="../assets/logo-black.png" class="mt-5" /> -->
    <v-list-item class="px-2 py-1 secondary" dark>
      <v-list-item-avatar>
        <v-img src="/logo.png" contain></v-img>
      </v-list-item-avatar>

      <v-list-item-title class="title">WhireLab</v-list-item-title>
    </v-list-item>
    <v-list dense nav>
      <template v-for="item in items">
        <v-list-item
          v-if="!item.childrens"
          :key="item.title"
          :to="item.url"
          :exact="item.exact"
          link
          active-class="primary"
          class="white--text"
        >
          <v-list-item-icon>
            <v-icon color="white">{{ item.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title class="text-left text-subtitle-1">{{
              item.title
            }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>
      <!-- <br /><br /><br /><br /><br /><br /><br /> -->
      <!-- <div class="my-5 mx-2">
        <div class="d-flex justify-space-between align-center">
          <div class="white--text text-left">{{ $t("workspace") }}</div>
          <div>
            <v-btn
              x-small
              outlined
              fab
              color="primary"
              tile
              class="workspace-btn mb-2"
            >
              <v-icon x-small>mdi-plus</v-icon>
            </v-btn>
          </div>
        </div>
        <v-divider class="primary"></v-divider>
      </div> -->

      <template v-for="(item) in items">
        <v-list-group
          v-if="item.childrens"
          :prepend-icon="item.icon"
          :key="item.title"
          no-action
          active-class="primary white--text"
        
        >
          <template v-slot:activator>
            <v-list-item-title class="white--text text-left">{{
              item.title
            }}</v-list-item-title>
          </template>

          <template v-for="child in item.childrens">
            <v-list-group
              v-if="child.childrens"
              :key="child.title"
              no-action
              sub-group
              active-class="primary"
            >
              <template v-slot:activator>
                <v-list-item-content>
                  <v-list-item-title>{{ child.title }} </v-list-item-title>
                </v-list-item-content>
              </template>

              <v-list-item
                v-for="(c, i) in child.childrens"
                :key="i"
                :to="c.url"
                :exact="true"
                link
                active-class="primary"
                class="white--text"
              >
                <v-list-item-title v-text="c.title"> </v-list-item-title>

                <!-- <v-list-item-icon>
                  <v-icon v-text="icon"></v-icon>
                </v-list-item-icon> -->
              </v-list-item>
            </v-list-group>

            <v-list-item
              v-else
              :key="child.url"
              :to="child.url"
              :exact="item.exact"
              router
              active-class="primary--text"
              class="px-3"
              style="width: 95%; margin-left: 5%"
            >
              <v-list-item>
                <v-list-item-icon>
                  <v-icon class="white--text" v-text="child.icon"></v-icon>
                </v-list-item-icon>
                <v-list-item-title
                  class="text-left"
                  v-text="child.title"
                ></v-list-item-title>
              </v-list-item>
            </v-list-item>
          </template>
        </v-list-group>
      </template>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
export default {
  props: ["propDrawer"],

  data: () => ({
    drawer: true,
    selectedItem: 0,
  }),

  watch: {
    propDrawer(val) {
      this.drawer = val;
    },
  },

  computed: {
    items() {
      return [
        {
          title: this.$t("home"),
          icon: " mdi-home",
          url: "/employee/training/list",
          show: true,
          exact: true,
        },
      
       
        // {
        //   title: "Meetings",
        //   icon: "mdi-calendar-multiselect",
        //   route: "/entity/meetings/list",
        //   show: true,
        //   childrens: [
        //     {
        //       title: this.$t('list'),
        //       url: "/entity/meetings",
        //       icon: "mdi-format-list-bulleted-square",
        //     },
        //     {
        //       title: this.$t('add_new'),
        //       url: "/entity/meetings/add",
        //       icon: "mdi-plus",
        //     },
        //   ],
        // },
        // {
        //   title: this.$t("trainings"),
        //   icon: "mdi-book-open-variant",
        //   route: "/employee/trainings",
        //   show: true,
        //   childrens: [
        //     {
        //       title: this.$t("list"),
        //       url: "/employee/training/list",
        //       icon: "mdi-format-list-bulleted-square",
        //     },
        //     // {
        //     //   title: this.$t("add_new"),
        //     //   url: "/employee/training/add",
        //     //   icon: "mdi-plus",
        //     // },
        //   ],
        // },
      ];
    },
  },
};
</script>

<style scoped>
.workspace-btn {
  min-width: 5px !important;
  width: 95%;
  height: 95%;
}

.v-list__group__header__prepend-icon .v-icon {
  color: red !important;
}
</style>