<template>
  <router-view />
</template>

<script>
import DashboardLayout from "@/layouts/DashboardLayout.vue";
export default {
  name: "EployeerRouters",

  data: () => ({
    alertDialog: true,
    alertMessage:
      "Confirme a sua subscrição para poder continuar. Envie-nos o comprovativo de transferência para o email pagamentos@whirelab.com. Para esclarecimento de dúvidas, por favor contacte nossa equipe de suporte.",
  }),

  created() {
    this.$emit(`update:layout`, DashboardLayout);
  },
 
  computed: {},
};
</script>
