const dashboard = () => import("../views/Dashboard.vue");
export default [
  {
    path: "dashboard",
    component: dashboard,
    meta: { requiresAuth: true },
    name: "dashboard",
    props: true,
  },
];
