const ListDepartments = () => import("../views/ListDepartments.vue");
const AddDepartment = () => import("../views/AddDepartment.vue");
const AddOrEditDepartment = () => import("../views/AddOrEditDepartment.vue");
const DepartmentDetail = () => import("../views/DepartmentDetail.vue");

export default [
  {
    path: "departments/list",
    component: ListDepartments,
    meta: { requiresAuth: true },
    name: "department",
  },
  {
    path: "departments/add",
    component: AddDepartment,
    meta: { requiresAuth: true },
    name: "department.add",
    props: true,
  },
  {
    path: "departments/edit/:id",
    component: AddOrEditDepartment,
    meta: { requiresAuth: true },
    name: "department.edit",
    props: true,
  },
  {
    path: "departments/show/:id",
    component: DepartmentDetail,
    meta: { requiresAuth: true },
    name: "department.show",
    props: true,
  },
];
