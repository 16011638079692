const ListDepartments = () => import("../views/ListDepartments.vue");
const AddDepartment = () => import("../views/AddDepartment.vue");
const AddOrEditDepartment = () => import("../views/AddOrEditDepartment.vue");
const DepartmentDetail = () => import("../views/DepartmentDetail.vue");

export default [
  {
    path: "clients/list",
    component: ListDepartments,
    meta: { requiresAuth: true },
    name: "client",
  },
  {
    path: "clients/add",
    component: AddDepartment,
    meta: { requiresAuth: true },
    name: "client.add",
    props: true,
  },
  {
    path: "clients/edit/:id",
    component: AddOrEditDepartment,
    meta: { requiresAuth: true },
    name: "client.edit",
    props: true,
  },
  {
    path: "clients/show/:id",
    component: DepartmentDetail,
    meta: { requiresAuth: true },
    name: "client.show",
    props: true,
  },
];
