import EployeerRouters from "../views/EployeeRouters.vue";
import dashboard from "./../modules/dashboard/router";
import training from "./../modules/training/router";
import activity from "./../modules/activity/router";
import meeting from "./../modules/meeting/router";
import profile from "./../modules/profile/router";

export default [
  {
  path: "/employee",
  component: EployeerRouters,
  // meta: { requiresAuth: true },
    children: [
      ...activity,
      ...dashboard,
      ...meeting,
      ...profile,
      ...training

    ],
  }, 
]