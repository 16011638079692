const ListTrainings = () => import('../views/ListTrainings.vue')
const ViewTraining = () => import('../views/ViewTraining.vue')
const ConfirmTraining = () => import('../views/ConfirmTraining.vue')
const ReadFile = () => import("../views/readFile.vue");
export default [
  {
    path: '/employee/training/list',
    component: ListTrainings,
    // meta: { requiresAuth: true },
    name: 'trainings'
  },
  {
    path: '/employee/trainings/show/:id',
    component: ViewTraining,
    // meta: { requiresAuth: true },
    name: 'training.profile',
    props: true
  },
  {
    path: '/employee/training/confirm-training',
    component: ConfirmTraining,
    // meta: { requiresAuth: true },
    name: 'confirm.profile',
    props: true
  },
  {
    path: '/employee/trainings/document/:file',
    component: ReadFile,
    meta: { requiresAuth: true },
    name: 'Profile',
    props: true
  }
]